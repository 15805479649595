@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.messageCard{
  background : rgba(200, 200, 200, 0.3);
  margin: 10px auto 10px auto;
  padding:5px;
  max-height: 250px;
  border: 1px 1px 1px 1px #000;
  display:block;
  
}
.messageCardTitle{

  color: #0256abc2;
  font-size: 14px;
  margin:5px;

}

.messageCardValue{

  color: #183f5a;
  font-size: 13px;
  margin:5px;

}
.messageCardSection{

  display: block;
  margin:5px;
  border-bottom: #da1d9b 1px;

}

.messageButton
{
  margin:5px;
}

div#root{

  max-width:1366px;
  display:block;
  margin : auto;

}